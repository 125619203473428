
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ArtigoCadastro from '@/components/ArtigoCadastro.vue';
import ArtigoConsulta from '@/components/ArtigoConsulta.vue';
import store from "@/store";
import "@/filters/filter"



@Component({
  components: {
    ArtigoConsulta,
    ArtigoCadastro
  },
  store
})
export default class App extends Vue {
  
  @Prop(String) readonly usuarioLogado: string | undefined
  public componente = 'ArtigoConsulta'

  get exibirOcultarMensagem() {
    return this.$store.state.exibirMensagemWebService.exibirMensagem
  }

  get parametrosMensagem() {
    return JSON.stringify(this.$store.state.exibirMensagemWebService)
  }

  get _modalLoading() {
    return this.$store.state.modal_loading
  }

  alteraComponente() {
    this.componente = 'ArtigoCadastro'
  }
 /* eslint-disable */
  fecharMensagem( event: CustomEventInit<any> ) {
    this.$store.commit('fecharMensagem', event)
  }

  CriarEventoFecharMensagem() {
    window.addEventListener('fecharMensagemRetorno', (e:CustomEventInit<any> ) => this.fecharMensagem(e.detail))
    this.$root.$on('evtOcultarMenu', () => this.$store.commit('fnExibirOcultarMenu',''))
  }

  registrarUsuarioLogado(){
    if(this.usuarioLogado){
      store.commit('registrarUsuarioLogado', this.usuarioLogado);
    }
  }

  @Watch('usuarioLogado')
  nomeUsuarioLogado(val: string){
    store.commit('registrarUsuarioLogado', val);
  }

  mounted(){
    this.registrarUsuarioLogado()
    this.CriarEventoFecharMensagem()
    store.dispatch('obterSistemas')
    store.dispatch('obterRecursos')
  }
}
