import Vue from 'vue'
import Vuex from 'vuex'
import { SistemasServices } from '@/services/sistemas.services'
import { ArtigoServices } from '@/services/artigos.services'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modal_loading: false,
    sistemas:[],
    recursos:[],
    artigos: {
      totalDeArtigos: 0,
      artigoRetornoDTOs: []},
    artigoEdicao: {},
    historicoCadastros: [],
    exibirMensagemWebService:{
      exibirMensagem: false,
      tipoMensagem: "",
      textoMensagem: ""
    },
    usuarioLogado: ''
  },
  mutations: {
    preencherMensagemWebService(state, payload) {
      state.exibirMensagemWebService = payload
    },
    fecharMensagem(state, payload) {
      state.exibirMensagemWebService.exibirMensagem = payload
    },
    sistemaObtidos(state,payload) {
      state.sistemas = payload
    },
    recursosObtidos(state,payload) {
      state.recursos = payload
    },
    artigosObtidos(state: statetype, payload:teste) {
      if(state.artigos.artigoRetornoDTOs.length > 0) {
        state.artigos.artigoRetornoDTOs.push(...payload.artigoRetornoDTOs)
      }else{
        state.artigos = payload
      }
    },
    artigoEditar(state,payload){
      state.artigoEdicao = payload
    },
    salvarHistoricoCadastros(state,payload) {
      state.historicoCadastros = payload
    },
    exibirOcultarModalLoading(state,payload) {
      state.modal_loading = payload
    },
    registrarUsuarioLogado(state,payload) {
      state.usuarioLogado = payload
    }
  },
  actions: {
    obterSistemas({commit, dispatch}){
      SistemasServices.obterSistemas(commit, dispatch)
    },
    obterRecursos({commit, dispatch}){
      SistemasServices.obterRecursos(commit, dispatch)
    },
    obterTodosArtigos({commit, dispatch}){
      ArtigoServices.obterTodosArtigos(commit, dispatch)
    },
    obterTodosArtigosPorPagina({commit, dispatch}, payload){
      return new Promise((resolve, reject) => {
        ArtigoServices.obterTodosArtigosPorPagina(commit, dispatch, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    obterArtigos({commit, dispatch}, payload){
      return new Promise((resolve, reject) => {
        ArtigoServices.obterArtigos(commit, dispatch, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    cadastrarArtigo({commit, dispatch}, payload){
      return new Promise((resolve, reject) => {
        ArtigoServices.cadastrarArtigo(commit, dispatch, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    deletarArtigo({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        ArtigoServices.deletarArtigo(commit, dispatch, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })

      })
    },
    montarMensagemErro({commit}, payload) {
      const message = {
      exibirMensagem: true,
      tipoMensagem: "erro",
      textoMensagem: payload.message ? payload.message : payload
      }
      commit('preencherMensagemWebService', message)
    }
  }
})

type teste = {
  artigoRetornoDTOs: Array<object>
  totalDeArtigos: number
}

type statetype = {
  modal_loading: boolean,
  sistemas: Array<object>,
  recursos:Array<object>,
  artigos: {
    totalDeArtigos: number,
    artigoRetornoDTOs: Array<object>},
  artigoEdicao: object,
  historicoCadastros: Array<object>,
  exibirMensagemWebService:{
    exibirMensagem: boolean,
    tipoMensagem: string,
    textoMensagem: string
  },
  usuarioLogado: string
}