import { Commit, Dispatch } from 'vuex'
import serviceGenerica from './service.generica'

export const SistemasServices = {
    obterSistemas,
    obterRecursos
}

function obterSistemas (commit: Commit, dispatch: Dispatch) {
    commit("exibirOcultarModalLoading", true)
    return serviceGenerica.get('/artigo-sistema')
    .then(function(response) {
        commit('sistemaObtidos', response.data)
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
       commit("exibirOcultarModalLoading", false)
       
    })
}

function obterRecursos (commit: Commit, dispatch: Dispatch) {
    commit("exibirOcultarModalLoading", true)
    return serviceGenerica.get('/artigo-recurso')
    .then(function(response) {
        commit('recursosObtidos', response.data)
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
       commit("exibirOcultarModalLoading", false)
       
    })
}