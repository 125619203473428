import { Commit, Dispatch, Payload } from 'vuex'
import serviceGenerica from './service.generica'

export const ArtigoServices = {
    obterTodosArtigos,
    obterArtigos,
    cadastrarArtigo,
    deletarArtigo,
    obterTodosArtigosPorPagina
}

function obterArtigos (commit: Commit, dispatch: Dispatch, payload: Payload) {
    commit("exibirOcultarModalLoading", true)
    const requestOptions = {
        params: {
            ...payload
        }
    }
    return serviceGenerica.get('/artigos', requestOptions)
    .then(function(response) {
        return response.data
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function obterTodosArtigos (commit: Commit, dispatch: Dispatch) {
    commit("exibirOcultarModalLoading", true)
    return serviceGenerica.get('/artigos/cadastros')
    .then(function(response) {
        commit('artigosObtidos', response.data)
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function obterTodosArtigosPorPagina (commit: Commit, dispatch: Dispatch, payload: Payload) {
    commit("exibirOcultarModalLoading", true)
    const requestOptions = {
        params: {
            ...payload
        }
    }
    return serviceGenerica.get('/artigos/filtro', requestOptions)
    .then(function(response) {
        return response.data
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function cadastrarArtigo (commit:Commit, dispatch: Dispatch, payload: Payload) {
    commit("exibirOcultarModalLoading", true)
    return serviceGenerica.post('/artigos/cadastros', payload)
    .then(response => {
       return response.status
    })
    .catch(error => {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (() =>{
        commit("exibirOcultarModalLoading", false)
    })
}

function deletarArtigo (commit:Commit, dispatch: Dispatch, payload: Payload) {
    commit("exibirOcultarModalLoading", true)
    return serviceGenerica.delete(`/artigos/cadastros/${payload}`)
    .then(function(response) {
       return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error.response.data)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}