import { ArtigoType } from '@/types/types'

export class Artigo {
    info = {
        link: ''
    }
    artigoRecurso: recursoType = {}
    artigoSistema: sistemaType = {
        idSistema: 1,
        sistema: 'NFeasy'
    }
    tipo = ''
    usuario = ''
    descricao = ''

    constructor(artigo?:ArtigoType){
        Object.assign(this, artigo)
    }

    public parser() {

        const objectParsed = {
            info: this.info,
            artigoRecurso: this.artigoRecurso.idRecurso,
            artigoSistema: this.artigoSistema.idSistema,
            tipo: `Rejeicao.${this.tipo}`,
            usuario: this.usuario ? this.usuario : 'sem_usuario',
            descricao: this.descricao        
        }

        Object.keys( objectParsed ).forEach( key => objectParsed[key as (keyof typeof objectParsed)] === "" ? delete objectParsed[key as (keyof typeof objectParsed)] : {} )
        
        
        return objectParsed
    }

    public parseTipo () {
        const tipoParser = this.tipo.split('.')
        return parseInt(tipoParser[1])
    }

    public validar() {
        let possuiErro = false
        if(!this.info.link){
            possuiErro = true
        }
        if(!this.artigoRecurso.idRecurso){
            possuiErro = true
        }
        if(!this.artigoSistema.idSistema){
            possuiErro = true
        }
        if(!this.tipo){
            possuiErro = true
        }
        return possuiErro
    }
}

// type artigoType = {
//     idArtigo: number
//     info: {
//         link: string
//     }
//     artigoRecurso: {
//         idRecurso: number
//         recurso: string
//     }
//     artigoSistema: {
//         idSistema: number,
//         sistema: string
//     }
//     tipo: string
//     usuario: string
//     descricao: string
// }

type recursoType = {
    idRecurso?: null | number
    recurso?: string
}

type sistemaType = {
    idSistema: null | number,
    sistema: string
}