// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://unpkg.com/@fortawesome/fontawesome-free@5.15.4/css/all.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-sem-spinner::-webkit-inner-spin-button,.input-sem-spinner::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}.input-paginacao label{float:left;padding:9px 6px 0 0;font-weight:700}.input-paginacao input{width:100px}.input-paginacao div{justify-content:right;padding-bottom:16px}.textarea{resize:none}.last-child{overflow-wrap:anywhere}.tabs{margin-top:-36px}.tabs span{color:#2e4283}.tabs.is-boxed li.is-active a{background-color:rgba(46,66,131,.102)!important}hr{margin:0;width:100%;height:3px;position:absolute;top:32px}.th-sistema{width:100px}.th-recurso{width:170px}.th-tipo{width:100px}#frmArtigoConsulta .table-container{margin:12px;height:calc(100vh - 495px)}#frmArtigoConsulta .last-child{overflow-wrap:anywhere}#frmArtigoConsulta th:last-child{width:50px}.possui-erro{margin-bottom:-18px}.possui-erro div,.possui-erro input,.possui-erro textarea{margin-bottom:-6px}.possui-erro input,.possui-erro select,.possui-erro textarea{border:1px solid red}.possui-erro span{padding-left:5px}#frmArtigoCadastro .table-container{margin:0 12px 12px 12px;height:300px}#frmArtigoCadastro .msg-validacao{color:red;font-size:10px;margin-top:-5px;position:absolute}.table-container{overflow-y:auto;border-radius:4px;border:1px solid #d8d8d8}thead{position:sticky;top:0;background-color:#2e4283!important}thead th{color:#fff!important}tbody{font-size:14px}button{background-color:#2e4283!important;color:#fff!important}button:hover{box-shadow:0 0 3px #6d6d6d}.fas:hover{transform:scale(1.5)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
