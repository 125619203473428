
import { Component, Vue } from 'vue-property-decorator';
import { Artigo } from '@/model/artigo'
import { ArtigoExclusaotype } from '@/types/types'
import NotiMessage from '@/utils/NotiMessage.vue'

@Component({
    components:{
        NotiMessage
    }
})
export default class ArtigoConsulta extends Vue {

    public artigo = new Artigo

    public sistema = {
        idSistema: 1,
        sistema: "NFeasy"
    }
    public recurso = {
        idRecurso: "",
        recurso: ""
    }
    public codigoRejeicao = ''

    public artigoExclusao: ArtigoExclusaotype = {
        artigo: {},
        i: undefined
    }

    public ocultarMostrarModal = false

    public campoComErro = false

    public paginaAtual = 1

    public itensPorPagina = 15

    public listaArtigos: Array<object> = []

    public totalArtigos = 0

    get _sistemas () {
        return this.$store.state.sistemas
    }

    get _recursos () {
        return this.$store.state.recursos
    }

    get _artigos () {
        return this.$store.state.artigos
    }

    openLink(link: string) {

       if (!link.match(/^http?:\/\//i)) {
            link = 'http://' + link;
        }
        window.open(link, '_blank')
    }

    editar(artigo: object) {
        this.$store.commit('artigoEditar',artigo)
        this.$emit('editarArtigo')
    }

    excluir(){
        this.ocultarMostrarModal = false
        this.$store.dispatch('deletarArtigo',this.artigoExclusao.artigo.idArtigo).then((response)=>{
            if(response.status === 204 && this.artigoExclusao.i) {
               this.listaArtigos.splice(this.artigoExclusao.i, 1)
            }
        });
    }

    buscarMaisResultados() {
        this.paginaAtual = this.paginaAtual + 1

        this.requisitarArtigos(this.paginaAtual)
    }

    consultarArtigos() {
        this.paginaAtual = 1
        this.requisitarArtigos(1)
    }

    requisitarArtigos(numPaginas: number) {
        
        this.campoComErro = false

        let payload = { 
            sistema: this.sistema.sistema,
            recurso: this.recurso.recurso,
            tipo: this.codigoRejeicao ? `Rejeicao.${this.codigoRejeicao}` : ''
        }

        if(this.recurso.recurso && !this.codigoRejeicao){
            this.campoComErro = true
            return
        }

        Object.keys( payload ).forEach( key => payload[key as (keyof typeof payload)] === "" ? delete payload[key as (keyof typeof payload)] : {} )

        if(!this.codigoRejeicao && !this.recurso.idRecurso) {
            this.itensPorPagina
            this.$store.dispatch('obterTodosArtigosPorPagina', {
                pagina: numPaginas,
                itensPorPagina: this.itensPorPagina
            }).then( response => {
                if(numPaginas > 1){
                    this.listaArtigos.push(...response.artigoRetornoDTOs)
                }else{
                    this.listaArtigos = response.artigoRetornoDTOs || []
                }
                this.totalArtigos = response.totalDeArtigos
            })
        }else{
            this.$store.dispatch('obterArtigos', payload).then( response => {
                this.listaArtigos = response || []
                this.totalArtigos = this.listaArtigos.length
            })
        }
    }

    mounted() {
        this.consultarArtigos()
    }

}
