
import { Component, Vue } from 'vue-property-decorator';
import { Artigo } from '@/model/artigo'
import { possuiErroType } from '@/types/types'


@Component
export default class ArtigoCadastro extends Vue {

  public artigo = new Artigo

  public tipo: string | number = ''

  public possuiErro: possuiErroType = {}

  public campoComErro = false

  get _historico () {
    return this.$store.state.historicoCadastros
  }

  get _sistemas () {
    return this.$store.state.sistemas
  }

  get _recursos () {
    return  this.$store.state.recursos
  }

  get _artigoEdicao () {
    return this.$store.state.artigoEdicao
  }

  get _usuarioLogado () {
    return this.$store.state.usuarioLogado
  }

  openLink(link: string) {

    if (!link.match(/^http?:\/\//i)) {
      link = 'http://' + link;
    }
    window.open(link, '_blank')
  }

  cadastrarArtigos() {
    this.artigo.tipo = this.tipo.toString()
    this.artigo.usuario = this._usuarioLogado
    this.campoComErro = this.artigo.validar()
    if(!this.campoComErro){
      this.$store.dispatch('cadastrarArtigo', this.artigo.parser()).then((response) => {
        if(response === 200) {
          let historico = this._historico
          historico.push({
            artigoSistema: this.artigo.artigoSistema.sistema,
            artigoRecurso: this.artigo.artigoRecurso.recurso,
            tipo: this.tipo,
            link: this.artigo.info.link,
          })
          this.$store.commit('salvarHistoricoCadastros',historico)
          this.artigo = new Artigo
          this.tipo = ''
        }
      })
    }
  }

  mounted(){
    if(Object.entries(this._artigoEdicao).length !== 0) {
      this.artigo = new Artigo(this._artigoEdicao)
      this.tipo = this.artigo.parseTipo()
    }
  }

}
