import axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'development' ?
 'https://nfeasy-admin-dev.alterdata.com.br/nfeasy-rest-api/publico':
 'https://nfeasy-admin.alterdata.com.br/nfeasy-rest-api/publico'

// const BASE_URL = 'https://nfeasy-admin.alterdata.com.br/nfeasy-rest-api/publico'

class ServiceGenerica {

    get(url: string, parametros?: object) {
        return axios(`${BASE_URL}${url}`, parametros)
    }

    post(url: string, parametros?: object) {
        return axios.post(`${BASE_URL}${url}`, parametros)
    }

    delete(url: string) {
        return axios.delete(`${BASE_URL}${url}`)
    }
}

const serviceGenerica = Object.freeze( new ServiceGenerica() )

export default serviceGenerica