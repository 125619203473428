import Vue from 'vue';

Vue.filter('formataExibicao', function (input: string) {
    if (input === undefined) return;

    const listaPalavras = input.replace(".", '')

    switch (listaPalavras) {
        case 'NFeNotaCompleta':
            return 'NFe - Nota completa';
        case 'NFeNotaRapida':
            return 'NFe - Nota rápida';
        case 'NFCeNotaRapida':
            return 'NFCe - Nota rápida';
        case 'Todos':
            return 'Todos';
        case 'MDFeNotaRapida':
            return 'MDFe - Nota rápida';
        default:
            return input.replace(".", ' - ')
    }
})

Vue.filter('removePrefixo', function (input: string) {
    if (input === undefined) return;

    const novoTexto = input.split('.')

    if(novoTexto.length > 1 ){
        return novoTexto[1]
    }

    return novoTexto[0]
})

Vue.filter('limiteCaracteres', function (input: string, val: number) {
    if (input === undefined) return;

    if(input.length < val){
        return input
    }else{
        return `${input.substring(0, val)}...`
    }

})
