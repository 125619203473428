
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotiMessage extends Vue {

    confirmado() {
        this.$emit('confirmado')
    }
    
    fecharModal() {
        this.$emit('fecharModal')
    }

    fecharAoPressionar(event: KeyboardEvent){
         if(event.key === 'Enter' || event.key === 'Escape'){
                 this.$emit('fecharModal')
           }
    }

    mounted(){
        document.addEventListener('keydown', (event) => {
            this.fecharAoPressionar(event)
        });
    }

    beforeDestroy() {
        document.removeEventListener('keydown', (event) => {
           this.fecharAoPressionar(event)
        });
    }

    

}
